export const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

export const isHealthAssuredLocale = (locale: string): boolean => locale === "en";

export const ukLocale = "dac1f6b0-3ee2-4e07-8bcc-61dbc5060e7b";

export const auLocale = "e89b9cc2-cee4-4829-9831-433406e0b792";

export const nzLocale = "65f2e9ac-7e74-4a71-b70b-d10efacadb3b";

export const localeCodeIds = {
  en: ukLocale,
  en_AU: auLocale,
  en_NZ: nzLocale,
};

export const LocaleIdToCodeMap: { [key: string]: string } = {
  [ukLocale]: "en",
  [auLocale]: "en_AU",
  [nzLocale]: "en_NZ",
};

export const LocaleIdToShortCodeMap: { [key: string]: string } = {
  [ukLocale]: "en",
  [auLocale]: "au",
  [nzLocale]: "nz",
};

export const localeIdToCountryCodeMap: { [key: string]: string } = {
  [ukLocale]: "+44",
  [auLocale]: "+61",
  [nzLocale]: "+64",
};

export const localeIdToRegionMap: { [key: string]: string } = {
  [ukLocale]: "eu",
  [auLocale]: "au",
  [nzLocale]: "nz",
};

export const LocalePhoneNumberLength: { [key: string]: { min: number; max: number } } = {
  [ukLocale]: {
    min: 7,
    max: 10,
  },
  [auLocale]: {
    min: 5,
    max: 15,
  },
  [nzLocale]: {
    min: 8,
    max: 12,
  },
};

export const DomainToGtmMap: { [key: string]: string | undefined } = {
  "wisdom.healthassured.org": process.env.REACT_APP_GTM_ID,
  "app.wisdomwellbeing.com": process.env.REACT_APP_AU_GTM_ID,
};

export const isAU = (localeId: string) => localeId === localeCodeIds["en_AU"];

export const AdminDomainLocaleCode: Record<string, "en" | "en_AU"> = {
  "backofficewisdom.healthassured.org": "en",
  "admin.wisdomwellbeing.com": "en_AU",
};

export const removeLocaleCountryCode = (phoneNumber: string, localeId: string) => {
  const regexPattern = new RegExp(`\\${localeIdToCountryCodeMap[localeId]}`, "g");
  const parsedPhoneNumber = phoneNumber?.replace(regexPattern, "");
  return parsedPhoneNumber;
};

export const parseLocalePhoneNumber = (phoneNumber: string, localeId: string) => {
  const parsedPhoneNumber = phoneNumber.replace(/^0+/, "");
  return `${localeIdToCountryCodeMap[localeId]}${parsedPhoneNumber}`;
};

export const localiseUrl = (localeId: string, url: string, showEnLocale?: boolean): string => {
  let parsedUri = url;
  const locale = LocaleIdToShortCodeMap[localeId];

  try {
    if (!isHealthAssuredLocale(locale) || (isHealthAssuredLocale(locale) && showEnLocale)) {
      const u = new URL(url);
      u.pathname = !u.pathname.startsWith(`/${locale}/`) ? `/${locale}${u.pathname}` : u.pathname;
      parsedUri = u.toString();
    }
  } catch (e) {
    return parsedUri;
  }
  return parsedUri;
};
