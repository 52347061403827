export const WisdomModules = {
  perksAndDiscounts: "perks-and-discounts",
  videoChat: "video-chat",
  moodTracker: "mood-tracker",
  achievements: "achievements",
  fourWeekPlan: "four-week-plans",
  leaderBoards: "leaderboards",
  trackers: "wellbeing-trackers",
  miniHealthChecks: "mini-health-checks",
  liveChat: "live-text-chat",
  requestCallBack: "request-call-back",
  helpline: "helpline",
} as const;

export const clientTypes = {
  STANDARD_CLIENT: "standard-client",
  WISDOM_LITE: "wisdom-lite",
};

export const modulePermissions = {
  legalInformation: ["en"],
  financialWellbeing: ["en"],
  liveChat: ["en"],
  videoChat: ["en"],
  wisdomAI: ["en"],
  safeSpaces: ["en"],
  acknowledgement: ["en_AU"],
  brightTv: ["en"],
};

export type ModulePermissionKeys = keyof typeof modulePermissions;

export type WisdomModule = (typeof WisdomModules)[keyof typeof WisdomModules];
