import { useEffect, useState } from "react";

import { getAuthData } from "../../request/twoFactorAuth";

enum ErrorCategories {
  EMPTY_CODE = "emptyCode",
  INCOMPLETE_CODE = "incompleteCode",
  INVALID_CODE = "invalidCode",
  GENERIC = "generic",
}

export const useGenerateRecoveryCodes = (fetchCode: boolean, otpCode?: string) => {
  const [codes, setCodes] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<ErrorCategories>();

  useEffect(() => {
    const uri = otpCode !== undefined ? "authenticator" : "recovery-codes";
    const body = otpCode ? { code: otpCode } : {};
    async function getCodes() {
      setLoading(true);
      try {
        const recoveryCodes = await getAuthData(uri, "post", body, "recoveryCodes");
        setCodes(recoveryCodes);
      } catch (err: any) {
        const { status, detail } = err.response.data;
        if (status === 400) {
          if (detail.includes("must not be empty")) {
            setError(ErrorCategories.EMPTY_CODE);
          } else if (detail.includes("must be 6-digits")) {
            setError(ErrorCategories.INCOMPLETE_CODE);
          } else {
            setError(ErrorCategories.GENERIC);
          }
        } else if (status === 409) {
          setError(ErrorCategories.INVALID_CODE);
        } else {
          setError(ErrorCategories.GENERIC);
        }
      } finally {
        setLoading(false);
      }
    }
    if (fetchCode) {
      getCodes();
    } else {
      setError(undefined);
    }
  }, [fetchCode, otpCode]);

  return { codes, loading, error };
};
