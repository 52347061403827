import { AccountSecurityModule } from "../../interfaces";
import { DeactivateModal } from "../TwoFactorAuthentication/Components/DeactivateModal/DeactivateModal";
import { EventNames } from "core/monitoring/types/enums";
import { ReactComponent as PasswordManagementIcon } from "shared/assets/Icons/password-management.svg";
import { RecoveryCodesModal } from "../TwoFactorAuthentication/Components/RecoveryCodesModal/RecoveryCodesModal";
import { ReactComponent as SingleSignOnIcon } from "shared/assets/Icons/single-sign-on.svg";
import { ReactComponent as TwoFactorIcon } from "shared/assets/Icons/two-factor.svg";
import theme from "../../../../../../../../../theme";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTwoFactorAuthSetup } from "shared/core/hooks/useTwoFactorAuth";

export const useAccountSecurityData = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "accountSecurity.home" });
  const [triggerReload, setTriggerReload] = useState(false);
  const { loading, error, secret } = useTwoFactorAuthSetup(triggerReload);
  const navyBlue = theme.colours["navy-blue"];

  const handleReloadTrigger = (reload: boolean) => setTriggerReload(reload);

  const accountSecurityModulesData: AccountSecurityModule[] = [
    {
      cta1: {
        id: t("passwordManagement.cta1.id"),
        text: t("passwordManagement.cta1.text"),
        link: t("passwordManagement.cta1.link"),
        eventName: EventNames.START_PASSWORD_CHANGE,
      },
      title: t("passwordManagement.title"),
      description: t("passwordManagement.description"),
      Icon: PasswordManagementIcon,
      enabled: !!process.env.REACT_APP_SHOW_PASSWORD_MANAGEMENT_FEATURE_TOGGLE,
    },
    {
      cta1: {
        id: t("singleSignOn.cta1.id"),
        text: t("singleSignOn.cta1.text"),
        link: t("singleSignOn.cta1.link"),
        eventName: EventNames.START_SINGLE_SIGNON,
      },
      title: t("singleSignOn.title"),
      description: t("singleSignOn.description"),
      info: {
        title: t("singleSignOn.info.title"),
        description: t("singleSignOn.info.description"),
        steps: t("singleSignOn.info.steps", { returnObjects: true }),
        endNote: t("singleSignOn.info.endNote"),
      },
      Icon: SingleSignOnIcon,
      enabled: !!process.env.REACT_APP_SHOW_SSO_FEATURE_TOGGLE,
    },
    {
      title: t("twoFactorAuth.title"),
      description: t("twoFactorAuth.description"),
      info: {
        title: t("twoFactorAuth.info.title"),
        description: t("twoFactorAuth.description"),
        steps: t("twoFactorAuth.info.steps", { returnObjects: true }),
        stepBannerInsert: t("twoFactorAuth.info.stepBannerInsert"),
        endNote: t("twoFactorAuth.info.endNote"),
      },
      Icon: TwoFactorIcon,
      cta1: {
        id: secret ? t("twoFactorAuth.cta1.setup.id") : t("twoFactorAuth.cta1.deactivate.id"),
        text: secret ? t("twoFactorAuth.cta1.setup.text") : t("twoFactorAuth.cta1.deactivate.text"),
        link: secret ? t("twoFactorAuth.cta1.setup.link") : null,
        loading,
        error: !!error,
        eventName: secret ? EventNames.SETUP_TWO_FA : EventNames.DEACTIVATE_TWO_FA,
        CtaModal: DeactivateModal,
        props: { triggerReload: handleReloadTrigger },
        style: secret && !error ? {} : { backgroundColor: navyBlue, borderColor: navyBlue },
      },
      ...(!loading &&
        !error &&
        !secret && {
          cta2: {
            id: t("twoFactorAuth.cta2.id"),
            text: t("twoFactorAuth.cta2.text"),
            link: null,
            eventName: EventNames.GENERATE_NEW_RECOVERY_CODES,
            CtaModal: RecoveryCodesModal,
            props: { status: "renew" },
          },
        }),
      enabled: !!process.env.REACT_APP_SHOW_2FA_FEATURE_TOGGLE,
    },
  ];

  return { accountSecurityModulesData };
};
