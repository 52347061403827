import { ReactComponent as GreenTickIcon } from "shared/assets/Icons/green-tick.svg";
import { ReactComponent as GreyCrossIcon } from "shared/assets/Icons/grey-cross.svg";
import { PasswordValidationRulesProps } from "./interfaces";
import { ValidationRule } from "../Form/interfaces";
import { useTranslation } from "react-i18next";

export const PasswordValidationRules = ({ dirtyFields, errors, validationRules }: PasswordValidationRulesProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: "accountSecurity.passwordManagement" });
  const getTickIcon = (b: boolean) => (b ? <GreenTickIcon className="h-5" /> : <GreyCrossIcon className="h-5" />);
  return (
    <div className="flex flex-col gap-2">
      {validationRules.map(({ testId, errorKey, translationKey }: ValidationRule) => (
        <div key={testId} data-testid={testId} className="flex items-center gap-2">
          {getTickIcon(!!(dirtyFields?.newPassword && !errors?.newPassword?.types?.[errorKey]))}
          <span>{t(translationKey)}</span>
        </div>
      ))}
    </div>
  );
};
