import { AchievementBadge } from "UIPalette/AchievementBadge/AchievementBadge";
import { ClientCodeState } from "shared/core/state/clientCodeState";
import { useGetUserAchievementsFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useTranslation } from "react-i18next";
import "shared/extensions/date";
import { useEffect } from "react";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { useUserContext } from "core/state/UserContext";
import { WisdomModules } from "shared/constants/Modules";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";

export type TrackerAchievementsProps = {
  trackerType: TrackerType;
};

export const TrackerAchievements = ({ trackerType }: TrackerAchievementsProps) => {
  const { isPermitted } = useUserContext();
  const { t } = useTranslation();
  const { getCurrentValue } = useTrackerContext();
  const { data, error, isLoading, mutate } = useGetUserAchievementsFunctionRequest({
    trackerType,
    xClientCode: ClientCodeState.get(),
  });

  useEffect(() => {
    if (data !== undefined) {
      mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCurrentValue]);

  if (!isPermitted(WisdomModules.achievements)) {
    return null;
  }

  const { awardedUserAchievements, availableUserAchievements } = data ?? {};

  return (
    <div>
      <h3>{t("healthHub.achievements.heading")}</h3>
      <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("healthHub.achievements.error")} onRetry={() => mutate()}>
        <div className="flex flex-row w-full justify-around">
          {awardedUserAchievements &&
            awardedUserAchievements.map((awardedBadge) => (
              <AchievementBadge
                key={awardedBadge.achievementId}
                name={awardedBadge.achievementId}
                caption={awardedBadge.achievementName}
                achieved={true}
                trackerType={trackerType}
              />
            ))}
          {availableUserAchievements &&
            availableUserAchievements.map((availableBadge) => (
              <AchievementBadge
                key={availableBadge.achievementId}
                name={availableBadge.achievementId}
                caption={availableBadge.achievementName}
                achieved={false}
                trackerType={trackerType}
              />
            ))}
        </div>
      </ViewStateWrapper>
    </div>
  );
};
