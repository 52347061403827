import "shared/extensions/date";

import { CONSENT_ERROR, EMAIL_NOT_SHARED, EMAIL_UNAVAILABLE, UNLINK_ERROR } from "./constants";
import { ModalVariants, ModalVariantsBody } from "./interfaces";
import { ProvidersToggles, useSSO } from "shared/core/hooks/useSSO/useSSO";
import { StatusBody, useStatusModal } from "core/hooks/useStatusModal";
import { useCallback, useEffect, useMemo, useState } from "react";

import { AppRoutes } from "core/AppRoutes";
import AppleLogo from "shared/assets/apple-logo.png";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import FacebookLogo from "shared/assets/facebook-logo.png";
import GoogleLogo from "shared/assets/google-logo.png";
import MicrosoftLogo from "shared/assets/microsoft-logo.png";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import PageTitle from "UIPalette/PageTitle/PageTitle";
import { SsoStatusBlocks } from "./Components/SsoStatusBlocks/SsoStatusBlocks";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const SsoSettings = () => {
  const { t: tPageTitle } = useTranslation();
  const { t } = useTranslation(undefined, { keyPrefix: "accountSecurity.ssoSettings" });
  const { Modal, setBody } = useStatusModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const provider = searchParams.get("provider");
  const qsError = searchParams.get("error");
  const idp = searchParams.get("idp");
  const ssoStatus = searchParams.get("status");
  const [currentProvider, setCurrentProvider] = useState(provider);
  const [currentModal, setCurrentModal] = useState<ModalVariants | null>(null);

  const validErrorStates = useMemo(() => [UNLINK_ERROR, CONSENT_ERROR, EMAIL_UNAVAILABLE, EMAIL_NOT_SHARED], []);

  const errors: Record<string, string> = useMemo(
    () => ({
      [UNLINK_ERROR]: t("error.body.unlink", { provider: currentProvider }),
      [CONSENT_ERROR]: t("error.body.consent", { idp: idp }),
      [EMAIL_UNAVAILABLE]: t("error.body.emailUnavailable", { idp: idp }),
      [EMAIL_NOT_SHARED]: t("error.body.emailNotShared", { provider: currentProvider, interpolation: { escapeValue: false } }),
    }),
    [currentProvider, idp, t]
  );

  const providersIcons: { [key: string]: string } = {
    Google: GoogleLogo,
    Microsoft: MicrosoftLogo,
    Apple: AppleLogo,
    Facebook: FacebookLogo,
  };

  const { unlink, setup, status } = useSSO();

  const dismissAction = useCallback(() => {
    searchParams.delete("success");
    searchParams.delete("error");
    setSearchParams((prev) => {
      const { success, ...rest } = prev as any;
      return rest;
    });
  }, [searchParams, setSearchParams]);

  const unlinkAction = useCallback(() => {
    currentProvider &&
      unlink(currentProvider).catch(() => {
        setSearchParams((prev) => {
          return {
            ...prev,
            error: UNLINK_ERROR,
          };
        });
      });
    dismissAction();
  }, [currentProvider, unlink, dismissAction, setSearchParams]);

  const handleToggle = (targetProvider: ProvidersToggles) => {
    if (status[targetProvider].id && status[targetProvider].disabled === false) {
      setCurrentModal("unlink");
      setCurrentProvider(targetProvider);
    } else {
      setup(targetProvider);
    }
  };

  const providerEmails = useMemo(
    () => ({
      Google: status.Google.email,
      Microsoft: status.Microsoft.email,
      Apple: status.Apple.email,
      Facebook: status.Facebook.email,
    }),
    [status.Apple.email, status.Facebook.email, status.Google.email, status.Microsoft.email]
  );

  const modalVariants: Record<ModalVariants, ModalVariantsBody> = useMemo(
    () => ({
      unlink: {
        title: t("unlink.title", { provider: currentProvider }),
        body: t("unlink.body", { provider: currentProvider, email: providerEmails[currentProvider as ProvidersToggles] }),
      },
      success: {
        title: t("success.title"),
        body: t("success.body"),
      },
      error: {
        title: t("error.title"),
        body: qsError ? errors[qsError] : "",
      },
    }),
    [currentProvider, errors, qsError, providerEmails, t]
  );

  const modalBody: StatusBody | null = useMemo(
    () =>
      currentModal !== null
        ? {
            status: currentModal === "error" ? "error" : "success",
            data: {
              title: modalVariants[currentModal].title,
              description: modalVariants[currentModal].body,
              cta: "I understand",
              dismissCta: currentModal === "unlink" ? "Cancel" : undefined,
            },
            callback: currentModal === "unlink" ? unlinkAction : dismissAction,
            dismissCallback: dismissAction,
            cancelCallback: currentModal === "unlink" ? dismissAction : undefined,
          }
        : null,
    [currentModal, modalVariants, unlinkAction, dismissAction]
  );

  useEffect(() => {
    if (validErrorStates.includes(qsError as any)) {
      setCurrentModal("error");
    } else if (ssoStatus === "success") {
      setCurrentModal("success");
    }
  }, [qsError, ssoStatus, validErrorStates]);

  useEffect(() => {
    if (currentModal && ["success", "error", "unlink"].includes(currentModal)) {
      setBody(modalBody);
    } else {
      setBody(null);
    }
  }, [currentModal, setBody, modalBody]);

  return (
    <>
      <PageTitle title={tPageTitle("pageTitle.account.accountSecurity.ssoSettings")} />
      <CurvedTopBanner>
        <PageHeader title={t("heading")} backRoute={AppRoutes.accountSecurity} />
      </CurvedTopBanner>
      <div className="mx-auto w-full md:w-[600px] space-y-8">
        <h3>{t("instruction")}</h3>
        <SsoStatusBlocks status={status} handleToggle={handleToggle} providersIcons={providersIcons} />
      </div>
      {Modal}
    </>
  );
};
