import { useCallback, useEffect, useState } from "react";

import { localeCodeIds } from "shared/core/locale";
import { useUserContext } from "core/state/UserContext";
import { changeLanguage } from "i18next";

export type ManualLocales = "en" | "en_AU" | "en_NZ";

export abstract class ManualLocaleState {
  public static get(): ManualLocales | undefined {
    return (localStorage.getItem("manualLocale") as ManualLocales) ?? undefined;
  }

  public static set(value: ManualLocales) {
    localStorage.setItem("manualLocale", value);
  }

  public static clear() {
    localStorage.removeItem("manualLocale");
  }
}

export default function useLocale() {
  const { user } = useUserContext();
  const [localeId, setLocaleId] = useState<string>(() => user?.localeId ?? localeCodeIds[ManualLocaleState.get() ?? "en"]);

  useEffect(() => {
    setLocaleId(user?.localeId ?? localeCodeIds[ManualLocaleState.get() ?? "en"]);
  }, [user]);

  const updateLocale = useCallback((newLocale: ManualLocales) => {
    ManualLocaleState.set(newLocale);
    setLocaleId(localeCodeIds[newLocale]);
    changeLanguage(newLocale);
  }, []);

  return { localeId, updateLocale } as const;
}
