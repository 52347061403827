import "shared/extensions/date";

import { AppRoutes } from "core/AppRoutes";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import PageTitle from "UIPalette/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { Setup } from "./Components/Setup/Setup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Verify } from "./Components/Verify/Verify";
import classNames from "classnames";
import { RecoveryCodesModal } from "./Components/RecoveryCodesModal/RecoveryCodesModal";
import { useModalContext } from "core/state/ModalContext/ModalContext";
import { trackBtnClicks } from "core/monitoring/Events";
import { EventNames } from "core/monitoring/types/enums";

export const TwoFactorAuthentication = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [otp, setOtp] = useState("");
  const { isVisible, hide, show } = useModalContext("verify_twofa_modal");

  const steps = useMemo(() => {
    return [
      {
        id: EventNames.SETUP_TWO_FA,
        Component: <Setup />,
      },
      {
        id: EventNames.VERIFY_TWO_FA,
        Component: <Verify otp={otp} handleChangeOtp={setOtp} />,
      },
    ];
  }, [otp]);

  const handleStepRender = steps[step].Component;

  const handleClick = useCallback(
    (action: "Next" | "Prev") => {
      const Next = step + 1;
      const Prev = step - 1;
      if (action === "Next") {
        if (step !== steps.length - 1) {
          setStep(Next);
          trackBtnClicks(steps[Next].id);
        } else {
          show();
        }
      } else if (action === "Prev") {
        setStep(Prev);
        setOtp("");
        trackBtnClicks(steps[Prev].id);
      }
    },
    [show, step, steps]
  );

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleClick("Next");
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleClick]);

  return (
    <>
      <PageTitle title={t("pageTitle.account.accountSecurity.index")} />
      <CurvedTopBanner>
        <PageHeader title={t("accountSecurity.twoFactorAuth.heading")} backRoute={AppRoutes.accountSecurity} />
      </CurvedTopBanner>

      <div className="mx-auto w-full xl:w-1/2 my-4 lg:my-8 flex items-center justify-center">
        <div className="w-full lg:w-3/4">
          {handleStepRender}
          <div
            className={classNames("flex items-center mt-16", {
              "justify-end": step === 0,
              "justify-between gap-6 lg:min-w-[500px]": step !== 0,
            })}
          >
            {step !== 0 && (
              <StylableButton
                color="primary"
                outlineButton
                type="submit"
                size="xl"
                fullWidth
                text={t("navigation.backButton")}
                onClick={() => handleClick("Prev")}
              />
            )}
            <StylableButton
              color="primary"
              type="submit"
              size="xl"
              fullWidth
              text={t(step === steps.length - 1 ? "navigation.submitButton" : "navigation.nextButton")}
              onClick={() => handleClick("Next")}
            />
          </div>
          <div className={classNames({ "min-h-[500px]": step !== 0 })} />
        </div>
      </div>
      <RecoveryCodesModal isVisible={isVisible} onDismiss={hide} status="new" code={otp} />
    </>
  );
};
