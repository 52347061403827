import { ProviderStatus, ProvidersToggles } from "shared/core/hooks/useSSO/useSSO";

import { ToggleCard } from "UIPalette/ToggleCard/ToggleCard";
import classNames from "classnames";

type SsoStatusBlocksProps = { status: { [K in ProvidersToggles]: ProviderStatus }; handleToggle: (targetProvider: ProvidersToggles) => void; providersIcons: { [key: string]: string; } }

export const SsoStatusBlocks = ({ status, handleToggle, providersIcons }: SsoStatusBlocksProps) => <div className="flex flex-col gap-12">{Object.entries(status).map(([key, value]: [string, ProviderStatus]) =>
  <div key={`${key}-provider-sso-status-block`} data-testid={`${key}-provider-sso-status-block`} className={classNames("transition-opacity duration-500 ease-in-out", { "opacity-50 pointer-events-none": status[key as ProvidersToggles].isLoading || status[key as ProvidersToggles].error })}>
    <ToggleCard isEnabled={!!value.id && !value.disabled && !value.error} onToggle={() => handleToggle(key as ProvidersToggles)}>
      <img height={64} width={100} alt={`${key}-provider-icon`} src={providersIcons[key]} />
      <p className="hidden w-full overflow-x-hidden text-right md:block text-ellipsis">{status[key as ProvidersToggles].email}</p>
    </ToggleCard>
  </div>)}</div>;