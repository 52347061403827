import Button from "@brighthr/component-button";
import { appInsightsLogger } from "core/monitoring/AppInsights";
import { trackBtnClicks, trackEvents } from "core/monitoring/Events";
import { EventNames } from "core/monitoring/types/enums";
import { useTranslation } from "react-i18next";
import { deactivateTwoFa } from "shared/request/twoFactorAuth";
import { Modal } from "shared/UI/Modal/Modal";
import { useStatusModal } from "core/hooks/useStatusModal";

interface DeactivateModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  triggerReload?: (reload: boolean) => void;
}

export const DeactivateModal = ({ isVisible, onDismiss, triggerReload }: DeactivateModalProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: "accountSecurity.twoFactorAuth.modal.deactivate" });
  const { setBody, Modal: StatusModal } = useStatusModal();

  const handleCancel = () => {
    trackBtnClicks(EventNames.CANCEL_DEACTIVATE_TWO_FA);
    onDismiss();
  };

  const handleDeactivate = async () => {
    try {
      await deactivateTwoFa();
      trackEvents(EventNames.COMPLETE_DEACTIVATE_TWO_FA);
      triggerReload && triggerReload(true);
      setBody({
        data: t("success", { returnObjects: true }),
        status: "success",
        callback: onDismiss,
      });
    } catch (err) {
      setBody({
        data: t("failure", { returnObjects: true }),
        status: "error",
      });
      appInsightsLogger.error("Failed to deactivate two factor Auth", err);
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Modal width="xs" close={handleCancel}>
        <div data-testid="two-fa-deactivate-modal" className="space-y-6 p-8">
          <h3 className="w-full p-0 text-center">{t("title")}</h3>
          <p className="w-full text-center">{t("description")}</p>
          <div className="space-y-2">
            <Button color="primary" fullWidth text={t("approve")} onClick={handleDeactivate} />
            <Button outlineButton fullWidth color="primary" text={t("cancel")} onClick={handleCancel} />
          </div>
        </div>
      </Modal>
      {StatusModal}
    </>
  );
};
