export type ToggleProps = {
  isEnabled: boolean;
  onToggle: () => void;
  className?: string;
};

export const Toggle = ({ isEnabled, onToggle, className = '' }: ToggleProps) => {
  return (
    <button
      data-testid="toggle-component"
      type="button"
      onClick={onToggle}
      className={`
        relative inline-flex h-8 w-14 items-center rounded-full
        ${isEnabled ? 'bg-d-blue' : 'bg-sky-grey'}
        transition-colors duration-200 ease-in-out
        ${className}
      `}
    >
      <span className="sr-only">Toggle Switch</span>
      <span
        data-testid="toggle-component-indicator"
        className={`
          inline-block h-6 w-6 transform rounded-full bg-white shadow
          transition-transform duration-200 ease-in-out
          ${isEnabled ? 'translate-x-7' : 'translate-x-1'}
        `}
      />
    </button>
  );
};
