import { Modal, ModalBody, ModalFooter } from "shared/UI/Modal/Modal";

import Button from "@brighthr/component-button";
import { t } from "i18next";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { handleCopy } from "core/helpers/copy";

export type InviteModalProps = {
  code: string;
  onClose: Function;
};

export const InviteModal = ({ code, onClose }: InviteModalProps) => {
  function copy() {
    handleCopy(code);
    toastNotify(t("leaderboards.invite.toast"), "success");
    onClose();
  }

  return (
    <Modal title={t("leaderboards.invite.heading")} hideDismiss close={() => {}}>
      <ModalBody>
        <div role="note">{t("leaderboards.invite.text")}</div>
        <div role="main" className="text-center mt-6">
          {code}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="flex flex-row w-full gap-3">
          <Button fullWidth text={t("leaderboards.invite.buttons.back")} color="primary" onClick={() => onClose()} />
          <Button fullWidth text={t("leaderboards.invite.buttons.copy")} color="primary" onClick={() => copy()} />
        </div>
      </ModalFooter>
    </Modal>
  );
};
