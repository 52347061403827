import "shared/extensions/date";

import { useTranslation } from "react-i18next";
import { QRCodeSVG } from "qrcode.react";
import { ReactComponent as AppleStoreSvg } from "shared/assets/AppleStore.svg";
import { ReactComponent as GooglePlaySvg } from "shared/assets/GooglePlay.svg";
import { externalLink } from "core/links";
import { UserState } from "core/state/userState";
import { useTwoFactorAuthSetup } from "shared/core/hooks/useTwoFactorAuth";
import { trackBtnClicks } from "core/monitoring/Events";
import { EventNames } from "core/monitoring/types/enums";

export const Setup = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "accountSecurity.twoFactorAuth.setup" });
  const user = UserState.get();
  const { secret } = useTwoFactorAuthSetup();
  const issuer = process.env.REACT_APP_APP_NAME;
  const qrCodeValue = `otpauth://totp/${issuer}:${user?.email}?secret=${secret}&issuer=${issuer}`;
  const splitIntoGroupsOfFour = /[\s\S]{1,4}/g;

  const handleStoreClick = (store: "apple" | "google") => {
    if (store === "apple") {
      trackBtnClicks(EventNames.AUTHENTICATOR_ON_APPSTORE);
      process.env.REACT_APP_AUTHENTICATOR_APPSTORE_URI && externalLink(process.env.REACT_APP_AUTHENTICATOR_APPSTORE_URI);
    }
    if (store === "google") {
      trackBtnClicks(EventNames.AUTHENTICATOR_ON_PLAYSTORE);
      process.env.REACT_APP_AUTHENTICATOR_PLAYSTORE_URI && externalLink(process.env.REACT_APP_AUTHENTICATOR_PLAYSTORE_URI);
    }
  };

  return (
    <div className="space-y-6 lg:space-y-8 text-xl lg:text-2xl font-bold">
      <h6 className="text-3xl">{t("title")}</h6>
      <div className="space-y-6">
        <div className="space-y-2">
          <h6>{t("qrCodeTitle")}</h6>
          <QRCodeSVG className="w-48 h-48 md:w-64 md:h-64" value={qrCodeValue} />
        </div>
        <div className="space-y-2">
          <h6>{t("manualCodeTitle")}</h6>
          <div className="rounded-lg px-8 py-4 lg:p-8 bg-silver font-normal text-center">
            <p className="lg:space-x-8 space-x-4">
              {secret?.match(splitIntoGroupsOfFour)?.map((str: string) => (
                <span data-testid="manualCodeSpan" key={str}>
                  {str}
                </span>
              ))}
            </p>
          </div>
        </div>
      </div>
      <div className="space-y-8 pt-4">
        <div>
          <h6>{t("NoAuthTitle")}</h6>
          <p className="font-normal text-xl">{t("NoAuthDescription")}</p>
        </div>
        <div className="flex justify-between gap-6">
          <AppleStoreSvg name="AppleStore" role="button" onClick={() => handleStoreClick("apple")} width={300} />
          <GooglePlaySvg role="button" name="GooglePlay" onClick={() => handleStoreClick("google")} width={300} />
        </div>
      </div>
    </div>
  );
};
