import CallbackIconImg from "shared/assets/Icons/callback.svg";
import PhoneIconImg from "shared/assets/Icons/phone-call.svg";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import SpeechBubbleIconImg from "shared/assets/Icons/speech-bubble.svg";
import StylableButton from "shared/UI/Buttons/StylableButton";
import VideoCallIconImg from "shared/assets/Icons/video-call-square.svg";
import PeakSvg from "shared/assets/Icons/peak.svg";
import { useTranslation } from "react-i18next";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { useWisdomLite } from "core/hooks/useWisdomLite";

export const WisdomLiteNotice = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "contactUs.wisdomLite" });
  const { isWisdomLite } = useWisdomLite();
  const handleFindMoreButtonClick = () => {
    const url = "mailto:" + t("contactAddress") + "?subject=" + t("emailSubject");
    window.open(url);
  };

  const supportData = [
    {
      icon: SpeechBubbleIconImg,
      text: t("description.line2"),
    },
    {
      icon: PhoneIconImg,
      text: t("description.line3"),
    },
    {
      icon: VideoCallIconImg,
      text: t("description.line4"),
    },
    {
      icon: CallbackIconImg,
      text: t("description.line5"),
    },
  ];

  return (
    <Hideable hidden={!isWisdomLite}>
      <div className="w-full py-4 md:order-3 md:p-4">
        <RoundedCard padding="p-6">
          <img src={PeakSvg} alt={"Peak Icon"} className={"mb-4 pl-[1px]"} />
          <h4 className="font-semibold text-black text-xl lg:text-2xl mb-2">{t("title")}</h4>
          <div className="flex flex-col gap-6">
            <p dangerouslySetInnerHTML={{ __html: t("description.line1") }} />
            <div className="space-y-4">
              {supportData.map((data) => {
                return (
                  <div className="flex space-x-4 items-center h-[30px]">
                    <img src={data.icon} alt={data.text} width={26} height={26} />
                    <p>{data.text}</p>
                  </div>
                );
              })}
            </div>
            <p>{t("description.line6")}</p>
            <StylableButton color={"primary"} fullWidth={true} onClick={() => handleFindMoreButtonClick()} text={t("buttonText")} size="lg" />
          </div>
        </RoundedCard>
      </div>
    </Hideable>
  );
};
