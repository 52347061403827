import classNames from "classnames";
import { useState, useRef, useEffect, ChangeEvent, Fragment, KeyboardEvent } from "react";
import useIsMobile from "shared/core/hooks/useIsMobile";

let currentOTPIndex: number = 0;

interface OTPInputProps {
  handleChange: (otp: string) => void;
}

const OTPInput = ({ handleChange }: OTPInputProps) => {
  const [otpList, setOtp] = useState(new Array(6).fill(""));
  const [activeOTPIndex, setActiveOTPIndex] = useState(0);
  const xsMobile = useIsMobile(300);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    const newOTP: string[] = [...otpList];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) setActiveOTPIndex(currentOTPIndex - 1);
    else setActiveOTPIndex(currentOTPIndex + 1);

    setOtp(newOTP);
    handleChange(newOTP.join(""));
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    currentOTPIndex = index;
    if (e.key === "Backspace") setActiveOTPIndex(currentOTPIndex - 1);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  return (
    <div className={"w-full flex justify-between items-center space-x-1 lg:space-x-4"}>
      {otpList.map((_, index) => {
        return (
          <Fragment key={index}>
            <input
              ref={activeOTPIndex === index ? inputRef : null}
              data-testid="otp-input"
              aria-label={`Please enter OTP character ${index + 1}`}
              type="number"
              className={classNames(
                "w-12 h-16 md:w-[70px] md:h-[80px] border-2 rounded-lg bg-transparent outline-none text-center font-semibold text-2xl spin-button-none border-brand focus:border-navy-blue transition",
                { "w-8 h-12": xsMobile }
              )}
              onChange={handleOnChange}
              onKeyDown={(e) => handleOnKeyDown(e, index)}
              value={otpList[index]}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default OTPInput;
