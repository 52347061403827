import { IReturn, ISWRConfig } from "../../../core/hooks/useGetRequest";

import { AxiosResponse } from "axios";
import { IResponseError } from "../../IResponseError";
import { client } from "../../../core/clients/brightAuthClient";
import { setAuthHeaders } from "../../../core/hooks/headers";
import { useEffect } from "react";
import useSWR from "swr";

export type SSOProviderModel = {
  id: string | null;
  email: string | null;
  usedForSignup?: boolean;
};

const handleStatusResponse = (res: AxiosResponse): AxiosResponse<SSOProviderModel | undefined> => {
  if (res.status === 204)
    return {
      ...res,
      data: { id: null, email: null, usedForSignup: false },
    };
  return res;
};

export const useSSOProviderStatusRequest = (
  { provider }: { provider: string },
  SWRConfig?: ISWRConfig<SSOProviderModel | undefined, IResponseError>
): IReturn<SSOProviderModel | undefined, IResponseError> => {
  const axiosConfig = {
    url: `/sso/${provider}/status`,
    method: "get",
    headers: { "Content-Type": "application/json" },
  };
  const { needsBearerToken = true, revalidateOnMountIfStale, showErrorIfStale = true, ...baseSWRConfig } = SWRConfig ?? {};
  setAuthHeaders(needsBearerToken, axiosConfig);

  if (revalidateOnMountIfStale !== undefined) {
    baseSWRConfig.revalidateOnMount = revalidateOnMountIfStale;
  }

  const {
    data: response,
    error,
    isValidating,
    mutate,
    isLoading,
  } = useSWR(axiosConfig.url, () => client(axiosConfig).then(handleStatusResponse), baseSWRConfig);

  useEffect(() => {
    if (revalidateOnMountIfStale === false && !response) {
      mutate();
    }
  }, [response, mutate, revalidateOnMountIfStale]);

  return {
    data: response && response.data,
    response: response,
    error: !showErrorIfStale && response ? undefined : error,
    isValidating,
    isLoading,
    mutate,
  };
};
