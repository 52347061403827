import { ReactComponent as CallUsIconAlt } from "shared/assets/Icons/phone-alternative.svg";
import { removeSpaces } from "shared/core/utils/string";
import { t } from "i18next";

const CallUsBlock = ({ code, number }: { code?: string; number: string }) => {
  let iconColor = "text-brand";

  switch (code) {
    case "UK":
      iconColor = "text-brand-aa";
      break;
    case "ROI":
      iconColor = "text-m-green";
      break;
    case "AU":
      iconColor = "text-m-yellow";
      break;
    case "NZ":
      iconColor = "text-m-yellow";
      break;
    default:
      break;
  }

  return (
    <>
      <CallUsIconAlt className={`w-12 ${iconColor}`} />
      <h4 className="ml-5">
        {`${code ? `${code}: ` : ""}${t("contactUs.callUs.title")}`}{" "}
        <a className="md:hidden" href={`tel:${removeSpaces(number)}`}>
          {number}
        </a>
        <span className="hidden md:inline">{number}</span>
      </h4>
    </>
  );
};

export default CallUsBlock;
