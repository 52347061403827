import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";

import { client } from "../../clients/brightAuthClient";
import { setAuthHeaders } from "../headers";
import { useSSOProviderStatusRequest } from "../../../request/hooks/useSSOProviderStatusRequest/useSSOProviderStatusRequest";

export type ProviderStatus = {
  id: string | null | undefined;
  email: string | null | undefined;
  disabled: boolean;
  isLoading: boolean;
  error: AxiosError | undefined;
};

export type ProvidersToggles = "Google" | "Microsoft" | "Apple" | "Facebook";

export const useSSO = (): {
  unlink: (provider: string) => Promise<AxiosResponse>;
  setup: (provider: string) => void;
  status: { [K in ProvidersToggles]: ProviderStatus };
} => {
  const googleHook = useSSOProviderStatusRequest({ provider: "Google" });
  const microsoftHook = useSSOProviderStatusRequest({ provider: "Microsoft" });
  const appleHook = useSSOProviderStatusRequest({ provider: "Apple" });
  const facebookHook = useSSOProviderStatusRequest({ provider: "Facebook" });

  const status: { [K in ProvidersToggles]: ProviderStatus } = useMemo(
    () => ({
      Google: {
        id: googleHook?.data?.id,
        email: googleHook?.data?.email,
        disabled: !!googleHook?.data?.usedForSignup,
        isLoading: googleHook?.isLoading,
        error: googleHook?.error,
      },
      Microsoft: {
        id: microsoftHook?.data?.id,
        email: microsoftHook?.data?.email,
        disabled: !!microsoftHook?.data?.usedForSignup,
        isLoading: microsoftHook?.isLoading,
        error: microsoftHook?.error,
      },
      Apple: {
        id: appleHook?.data?.id,
        email: appleHook?.data?.email,
        disabled: !!appleHook?.data?.usedForSignup,
        isLoading: appleHook?.isLoading,
        error: appleHook?.error,
      },
      Facebook: {
        id: facebookHook?.data?.id,
        email: facebookHook?.data?.email,
        disabled: !!facebookHook?.data?.usedForSignup,
        isLoading: facebookHook?.isLoading,
        error: facebookHook?.error,
      },
    }),
    [
      googleHook?.data?.id,
      googleHook?.data?.email,
      googleHook?.data?.usedForSignup,
      googleHook?.isLoading,
      googleHook?.error,
      microsoftHook?.data?.id,
      microsoftHook?.data?.email,
      microsoftHook?.data?.usedForSignup,
      microsoftHook?.isLoading,
      microsoftHook?.error,
      appleHook?.data?.id,
      appleHook?.data?.email,
      appleHook?.data?.usedForSignup,
      appleHook?.isLoading,
      appleHook?.error,
      facebookHook?.data?.id,
      facebookHook?.data?.email,
      facebookHook?.data?.usedForSignup,
      facebookHook?.isLoading,
      facebookHook?.error,
    ]
  );

  const mutateMap = useMemo(
    (): Record<ProvidersToggles, () => void> => ({
      Google: googleHook.mutate,
      Microsoft: microsoftHook.mutate,
      Apple: appleHook.mutate,
      Facebook: facebookHook.mutate,
    }),
    [appleHook?.mutate, facebookHook?.mutate, googleHook?.mutate, microsoftHook?.mutate]
  );

  const setup = useCallback((provider: string) => {
    const { REACT_APP_AUTH_AUTHORITY: authServerAPI } = process.env;
    const returnUri = encodeURI(`${window.location.origin}/sso?provider=${provider}&status=success`);
    const uri = `${authServerAPI}/sso/${provider}/setup?returnUrl=${returnUri}`;

    return window.location.assign(uri);
  }, []);

  const unlink = useCallback(
    async (provider: string) => {
      const headers = {};
      const axiosConfig = {};
      setAuthHeaders(true, { headers, ...axiosConfig });

      return client.post(`/sso/${provider}/unlink`, undefined, axiosConfig).then((res) => {
        mutateMap[provider as ProvidersToggles]();
        return res;
      });
    },
    [mutateMap]
  );
  return { unlink, setup, status };
};
