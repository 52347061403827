import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { AccountSecurityModule } from "../../../../interfaces";
import { useNavigate } from "react-router-dom";
import { ModuleInfoAccordion } from "../ModuleInfoAccordion/ModuleInfoAccordion";
import { useModalContext } from "core/state/ModalContext/ModalContext";
import Button from "@brighthr/component-button";
import { trackBtnClicks } from "core/monitoring/Events";

export const AccountSecurityModuleCard = ({ cta1, cta2, title, description, info, Icon }: AccountSecurityModule) => {
  const navigate = useNavigate();
  const { isVisible, hide, show } = useModalContext(cta1?.id);
  const { isVisible: isVisible2, hide: hide2, show: show2 } = useModalContext(cta2?.id);

  const handleClickAction = async (index: number) => {
    const cta = index === 2 ? cta2 : cta1;
    const callback = index === 2 ? show2 : show;

    cta?.eventName && trackBtnClicks(cta?.eventName);
    if (cta?.error) {
      window.location.reload();
    } else if (!cta?.link) {
      callback();
    } else {
      navigate(cta?.link);
    }
  };

  const buttonText = (text: string, loading?: boolean, error?: boolean) => {
    let textValue = text;

    if (loading) {
      textValue = "Loading";
    }

    if (error) {
      textValue = "Try Again";
    }

    return textValue;
  };

  return (
    <>
      <RoundedCard padding="p-0" className="text-lg lg:text-xl">
        <div className="flex md:items-center justify-between md:space-x-10 p-5 md:p-10 flex-col-reverse md:flex-row">
          <div className="flex-col justify-between space-y-8 max-w-5xl bg-indigo-500 mt-3 md:mt-0">
            <div className="flex-1">
              <h3 className="cardTitle">{title}</h3>
              <p>{description}</p>
            </div>

            <div className="mt-8 flex-1 flex flex-col md:flex-row gap-6">
              {cta1 && (
                <div className="md:w-[200px] w-full ">
                  <Button
                    color="primary"
                    disabled={cta1.loading}
                    fullWidth
                    text={buttonText(cta1.text, cta1.loading, cta1.error)}
                    aria-label={`${title}-${cta1.text}`}
                    size="xl"
                    onClick={() => handleClickAction(1)}
                    style={cta1.style}
                  />
                </div>
              )}
              {cta2 && (
                <div className="md:w-[200px] w-full">
                  <Button
                    disabled={cta2.loading}
                    color="primary"
                    outlineButton
                    fullWidth
                    text={buttonText(cta2.text, cta2.loading, cta2.error)}
                    aria-label={`${title}-${cta2.text}`}
                    size="xl"
                    onClick={() => handleClickAction(2)}
                    style={cta2.style}
                  />
                </div>
              )}
            </div>
          </div>
          {Icon && (
            <div data-testid="account-security-module-icon" className="w-20 h-20 md:w-32 md:h-32 lg:w-48 lg:h-48">
              <Icon style={{ width: "inherit", height: "inherit" }} />
            </div>
          )}
        </div>
        {info && <ModuleInfoAccordion {...info} />}
      </RoundedCard>
      {cta1?.CtaModal && <cta1.CtaModal isVisible={isVisible} onDismiss={hide} {...cta1.props} />}
      {cta2?.CtaModal && <cta2.CtaModal isVisible={isVisible2} onDismiss={hide2} {...cta2.props} />}
    </>
  );
};
