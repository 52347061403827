import { useEffect, useState } from "react";
import { getAuthData } from "../../request/twoFactorAuth";

export const useTwoFactorAuthSetup = (triggerReload?: boolean) => {
  const [secret, setSecret] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<string>();

  async function getSecret() {
    setLoading(true);
    try {
      const secretKey: string = await getAuthData("authenticator/setup", "post", {}, "secret");
      setSecret(secretKey);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getSecret();
  }, []);

  useEffect(() => {
    if (triggerReload) {
      getSecret();
    }
  }, [triggerReload]);

  return { secret, loading, error };
};
