import "shared/extensions/date";

import { Route, Routes } from "react-router-dom";

import { AccountSecurityHome } from "./Views/AccountSecurityHome/AccountSecurityHome";
import { FeatureToggle } from "core/auth/FeatureToggle";
import { PasswordManagement } from "./Views/PasswordManagement/PasswordManagement";
import { SsoSettings } from "./Views/SsoSettings/SsoSettings";
import { TwoFactorAuthentication } from "./Views/TwoFactorAuthentication/TwoFactorAuthentication";

export const AccountSecurity = () => {
  const [showPasswordManagement, showSso, showTwoFactorAuth] = [
    !!process.env.REACT_APP_SHOW_PASSWORD_MANAGEMENT_FEATURE_TOGGLE,
    !!process.env.REACT_APP_SHOW_SSO_FEATURE_TOGGLE,
    !!process.env.REACT_APP_SHOW_2FA_FEATURE_TOGGLE,
  ];
  return (
    <Routes>
      <Route index element={<AccountSecurityHome />} />
      <Route path="password-management" element={
        <FeatureToggle showFeature={showPasswordManagement}>
          <PasswordManagement />
        </FeatureToggle>} />
      <Route path="sso" element={
        <FeatureToggle showFeature={showSso}>
          <SsoSettings />
        </FeatureToggle>} />
      <Route path="two-factor-auth" element={
        <FeatureToggle showFeature={showTwoFactorAuth}>
          <TwoFactorAuthentication />
        </FeatureToggle>} />
    </Routes>
  );
};
