import { PropsWithChildren } from "react";
import { Toggle } from "UIPalette/Toggle/Toggle";
import classNames from "classnames";

export type ToggleCardProps = PropsWithChildren & {
  isEnabled: boolean;
  onToggle: () => void;
  className?: string;
};

export const ToggleCard = ({ className, isEnabled, onToggle, children }: ToggleCardProps) => {
  return (
    <div className={classNames("p-4 gap-4 flex justify-between items-center w-full rounded shadow h-16", className)}>
      {children}
      <Toggle className="shrink-0" isEnabled={isEnabled} onToggle={onToggle} />
    </div>
  );
};
