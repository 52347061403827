import { useUserContext } from "core/state/UserContext";
import { WisdomModules } from "shared/constants/Modules";

export const useWisdomLite = () => {
  const { isPermitted } = useUserContext();

  const isWisdomLite =
    !isPermitted(WisdomModules.requestCallBack) &&
    !isPermitted(WisdomModules.liveChat) &&
    !isPermitted(WisdomModules.videoChat) &&
    !isPermitted(WisdomModules.helpline);

  return { isWisdomLite };
};
