import "shared/extensions/date";

import { AccountSecurityModule } from "../../interfaces";
import { AccountSecurityModuleCard } from "./Components/ModuleCard/ModuleCard";
import { AppRoutes } from "core/AppRoutes";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import PageTitle from "UIPalette/PageTitle/PageTitle";
import { ResponsiveBreakpoints } from "shared/UI/ResponsiveBreakpoints";
import { useAccountSecurityData } from "./data";
import { useMatchMedia } from "shared/UI/Hooks/useMatchMedia";
import { useTranslation } from "react-i18next";

export const AccountSecurityHome = () => {
  const { t } = useTranslation();
  const largerDisplay = useMatchMedia(ResponsiveBreakpoints.Desktop, false);
  const { accountSecurityModulesData } = useAccountSecurityData();
  return (
    <>
      <PageTitle title={t("pageTitle.account.accountSecurity.index")} />
      <CurvedTopBanner>
        <PageHeader title={t("accountSecurity.home.heading")} backRoute={largerDisplay ? AppRoutes.home : AppRoutes.account} />
      </CurvedTopBanner>
      <div className="flex flex-col space-y-12">
        {accountSecurityModulesData.filter((module) => module.enabled).map((data: AccountSecurityModule) => (
          <AccountSecurityModuleCard key={data.title} {...data} />
        ))}
      </div>
    </>
  );
};
