const removeSpaces = (str: string): string => str.replace(/\s/g, "");

function fromKebabToTitleCase(input: string) {
  return input
    ?.toLowerCase()
    ?.split("-")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(" ");
}

export { removeSpaces, fromKebabToTitleCase };
