import useLocale from "core/hooks/useLocale/useLocale";
import { trackEvents } from "core/monitoring/Events";
import { EventNames } from "core/monitoring/types/enums";
import { useEffect, useState } from "react";
import { useLoadExternalScript } from "shared/core/hooks/useLoadExternalScript";
import { localeIdToRegionMap } from "shared/core/locale";

export type VideoCallHook = {
  isVideoCallAvailable: boolean;
  startVideoCall: VoidFunction;
};

const fetchRegionUrl = (localeId: string) => {
  const activeRegions = ["eu"];
  const localeRegion = localeIdToRegionMap[localeId];

  const getUrl = (region: string) =>
    `https://${region}.engage.app/api/ecs/v1/loader/8f8d0cbd-ee94-4553-8954-6b1d8ed7f920.js?path=${encodeURIComponent(
      window.location.origin + window.location.pathname
    )}&selectedVersion=${new URLSearchParams(window.location.search).get("ecsSelectedVersion") || ""}`;

  return activeRegions.includes(localeRegion) ? getUrl(localeRegion) : "";
};

export const useVideoCall = (): VideoCallHook => {
  const { localeId } = useLocale();
  const talkativeScriptUrl = fetchRegionUrl(localeId);
  const [isVideoCallAvailable, setIsAvailable] = useState(window.talkativeApi?.isReady ?? false);
  const scriptState = useLoadExternalScript(talkativeScriptUrl);

  function startVideoCall() {
    trackEvents(EventNames.VIDEO_CHAT_START);
    window.talkativeApi?.actions.triggerAction("startChat");
  }

  useEffect(() => {
    if (scriptState === "ready") {
      // These are the events that talkative script can invoke, I have added all incase we wish to use them in the future
      // see this example from talkative https://github.com/talkative-tech/widget-examples/blob/main/event-hooks/main.js
      // for more info see /.markdown/Talkative/TalkativeVideoCall.md
      window.talkativeCustomConfig = {
        events: {
          enterStandby: () => window.talkativeApi?.ui.hide(),
          enterInteraction: () => window.talkativeApi?.ui.show(),
          completeInteraction: () => {},
          resumeInteraction: () => {},
          exitInteraction: () => {
            trackEvents(EventNames.VIDEO_CHAT_END);
          },
          ready: () => {
            setIsAvailable(true);

            if (window.talkativeApi) {
              window.talkativeApi.isReady = true;
            }
          },
          sendEmail: () => {},
          presenceFail: () => {},
          qosFail: () => {},
          rateLimitFail: () => {},
          visibilityChange: ({ visibility }) => {},
          availabilityChange: ({ isAvailable }) => {},
        },
      };
    } else {
      setIsAvailable(false);
      if (window.talkativeApi) {
        window.talkativeApi.isReady = false;
      }
    }
  }, [scriptState]);

  return { isVideoCallAvailable, startVideoCall };
};

export interface ITalkativeApi {
  actions: {
    triggerAction: (actionName: string) => {};
  };
  ui: {
    hide: VoidFunction;
    show: VoidFunction;
  };
  isReady: boolean;
}

export interface ITalkativeCustomConfig {
  events: {
    enterStandby: VoidFunction;
    enterInteraction: VoidFunction;
    exitInteraction: VoidFunction | undefined;
    resumeInteraction: VoidFunction | undefined;
    completeInteraction: VoidFunction | undefined;
    ready: VoidFunction | undefined;
    sendEmail: VoidFunction | undefined;
    presenceFail: VoidFunction | undefined;
    qosFail: VoidFunction | undefined;
    rateLimitFail: VoidFunction | undefined;
    availabilityChange: ({ isAvailable }: { isAvailable: boolean }) => void | undefined;
    visibilityChange: ({ visibility }: { visibility: boolean }) => void | undefined;
  };
}

declare global {
  interface Window {
    talkativeApi: ITalkativeApi | undefined;
    talkativeCustomConfig: ITalkativeCustomConfig | undefined;
  }
}
