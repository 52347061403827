import { Modal, ModalBody } from "shared/UI/Modal/Modal";
import useLocale, { ManualLocaleState, ManualLocales } from "core/hooks/useLocale/useLocale";

import StylableButton from "shared/UI/Buttons/StylableButton";
import { t } from "i18next";
import { useModalContext } from "core/state/ModalContext/ModalContext";
import { trackBtnClicks } from "core/monitoring/Events";

export const LocaleOption = (props: { handleLocaleClick: (locale: ManualLocales) => void }) => {
  const { handleLocaleClick } = props;
  return (
    <div className="flex justify-center gap-4">
      <StylableButton color="primary" text={t("localeModel.buttons.first")} onClick={() => handleLocaleClick("en")} />
      <StylableButton color="primary" text={t("localeModel.buttons.second")} onClick={() => handleLocaleClick("en_AU")} />
      <StylableButton color="primary" text={t("localeModel.buttons.third")} onClick={() => handleLocaleClick("en_NZ")} />
    </div>
  );
};

export const LocaleModal = () => {
  const { isVisible, hide } = useModalContext("localeModal");
  const { updateLocale } = useLocale();

  const dismissAction = (locale: ManualLocales = "en") => {
    trackBtnClicks(`locale-selected-${locale}`);
    updateLocale(locale);
    hide();
  };

  return (
    <>
      {isVisible && !ManualLocaleState.get() && (
        <Modal title={t("localeModel.title")} width="xs" close={() => dismissAction("en")} hideDismiss={true} bgDismiss={false}>
          <ModalBody>
            <LocaleOption handleLocaleClick={dismissAction} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
