import { client } from "../core/clients/brightAuthClient";
import { setAuthHeaders } from "../core/hooks/headers";

export const getAuthData = async (uri: string, action: "get" | "post" | "delete", body: Record<string, any>, key: string) => {
  const headers = {};
  let axiosConfig = {};
  axiosConfig = setAuthHeaders(true, { headers, ...axiosConfig });
  const { data } =
    action === "delete" ? await client.delete(`v1/account/${uri}`, axiosConfig) : await client[action](`v1/account/${uri}`, body, axiosConfig);
  return data[key];
};

export const deactivateTwoFa = async () => await getAuthData("authenticator", "delete", {}, "");
