import useLocale from "core/hooks/useLocale/useLocale";
import { ModulePermissionKeys, modulePermissions } from "shared/constants/Modules";
import { FC, ReactNode } from "react";
import { LocaleIdToCodeMap } from "shared/core/locale";
import { Hideable } from "shared/UI/Hideable/Hideable";
interface LocalizedWrapperProps {
  module: ModulePermissionKeys;
  children: ReactNode;
  onHiddenStateChange?: (localeCondition: boolean) => boolean;
}

const LocalizedWrapper: FC<LocalizedWrapperProps> = ({ module, children, onHiddenStateChange }) => {
  const { localeId } = useLocale();
  const isLocaleHidden = !modulePermissions[module]?.includes(LocaleIdToCodeMap[localeId]);

  const hideChildren = onHiddenStateChange ? onHiddenStateChange(isLocaleHidden) : isLocaleHidden;

  return <Hideable hidden={hideChildren}>{children}</Hideable>;
};

export default LocalizedWrapper;
