export enum EventNames {
  CARD_ONCLICK = "card_click",
  BTN_ONCLICK = "btn_click",
  CHART_FILTER = "chart_filter",
  SAFE_SPACE_CLICK = "safe_spaces_click",
  WISDOM_AI_CLICK = "wisdom_AI_click",
  BRIGHT_EX_CLICK = "bright_exchange_click",
  PHONE_CALL_START = "start_phone_call",
  LIVE_CHAT_START = "start_live_chat",
  LIVE_CHAT_END = "end_live_chat",
  VIDEO_CHAT_START = "start_video_chat",
  VIDEO_CHAT_END = "end_video_chat",
  RESOURCE_FILTER = "resource_filter",
  FORM_SUBMIT = "form_submit",
  NAV_SEARCH = "nav_search",
  BREATHING_EXERCISE_START = "start_breathing_exercise",
  BREATHING_EXERCISE_COMPLETE = "complete_breathing_exercise",
  MINI_HEALTH_CHECK_START = "start_mini_health",
  MINI_HEALTH_CHECK_END = "complete_mini_health",
  HEALTH_DATA_SAVE = "save_health_data",
  SAVE_HEALTH_DATA_GOAL = "save_health_data_goal",
  PREVIEW_LEADERBOARD = "preview_leaderboard",
  START_PASSWORD_CHANGE = "start_password_change",
  START_SINGLE_SIGNON = "start_single_signon",
  SETUP_TWO_FA = "setup_two_factor_auth",
  VERIFY_TWO_FA = "verify_two_factor_auth",
  DEACTIVATE_TWO_FA = "deactivate_two_factor_auth",
  CANCEL_DEACTIVATE_TWO_FA = "cancel_deactivate_two_fa",
  COMPLETE_DEACTIVATE_TWO_FA = "complete_deactivate_two_fa",
  GENERATE_NEW_RECOVERY_CODES = "generate_new_recovery_codes",
  COPY_RECOVERY_CODES = "copy_recovery_codes",
  DOWNLOAD_RECOVERY_CODES = "download_recovery_codes",
  COMPLETE_RECOVERY_CODES_PROCESS = "complete_recovery_codes_process",
  AUTHENTICATOR_ON_APPSTORE = "authenticator_on_appstore",
  AUTHENTICATOR_ON_PLAYSTORE = "authenticator_on_playstore",
  PASSWORD_CHANGE_SUBMIT = "password_change_submit",
  PASSWORD_CHANGE_OPEN = "password_change_open",
}
