import Button from "@brighthr/component-button";
import theme from "../../../../../theme";
import { ReactComponent as ErrorIconSvg } from "shared/assets/Error_Icon.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "shared/UI/Modal/Modal";
import { useModalContext } from "core/state/ModalContext/ModalContext";

export interface StatusBody {
  data: {
    title: string;
    description: string;
    cta: string;
    dismissCta?: string;
  };
  status: "success" | "error";
  dismissCallback?: () => void;
  cancelCallback?: () => void;
  callback?: () => void;
}

export const useStatusModal = () => {
  const [body, setBody] = useState<StatusBody | null>(null);
  const { isVisible: isVisibleError, hide: hideError, show: showError } = useModalContext("error-modal");
  const { isVisible: isVisibleSuccess, hide: hideSuccess, show: showSuccess } = useModalContext("success-modal");

  const handleCallbacks = useCallback(
    (callback?: () => void) => {
      hideError();
      hideSuccess();
      callback && callback();
    },
    [hideError, hideSuccess]
  );

  const handleCancel = useCallback(() => handleCallbacks(body?.cancelCallback), [body?.cancelCallback, handleCallbacks]);

  const handleDismiss = useCallback(() => handleCallbacks(body?.dismissCallback), [body?.dismissCallback, handleCallbacks]);

  const handleConfirm = useCallback(() => handleCallbacks(body?.callback), [body?.callback, handleCallbacks]);

  const modalComponent = useMemo(
    () =>
      body ? (
        <Modal width="xs" close={handleDismiss}>
          <div data-testid="error-modal" className="p-8 space-y-4">
            {body?.status === "error" && (
              <div className="flex justify-center w-full item-center">
                <ErrorIconSvg width={50} height={50} />
              </div>
            )}

            <h3 className="w-full p-0 text-center">{body?.data.title}</h3>
            <p className="w-full text-center">{body?.data.description}</p>
            <div className="space-y-2">
              <Button
                fullWidth
                text={body?.data.cta}
                onClick={handleConfirm}
                size="xl"
                color="primary"
                style={body?.status === "error" ? { backgroundColor: theme.colours["brand-grey"], border: "none", color: theme.colours.black } : {}}
              />
            </div>
            {body?.cancelCallback && (
              <button
                data-testid="sso-modal-cancel-button"
                onClick={handleCancel}
                className="w-full text-sm p-1.5 underline bg-transparent border-none"
              >
                {"Cancel"}
              </button>
            )}
          </div>
        </Modal>
      ) : null,
    [body, handleConfirm, handleDismiss, handleCancel]
  );

  useEffect(() => {
    if (!!body) {
      if (body.status === "error") {
        showError();
      }
      if (body.status === "success") {
        showSuccess();
      }
    } else if (!!modalComponent) {
      handleDismiss();
    }
  }, [body, hideError, hideSuccess, showError, showSuccess, handleDismiss, modalComponent]);

  return {
    Modal: isVisibleError || isVisibleSuccess ? modalComponent : null,
    setBody,
  };
};
