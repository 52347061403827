import copy from "copy-to-clipboard";
import { copyToClipboard } from "core/javascriptChannels";

export const handleCopy = (value: string) => {
  if (!!window.flutter_inappwebview) {
    copyToClipboard(value);
  } else {
    copy(value);
  }
};
