import BrightExchangeLogo from "shared/assets/BrightExchangeLogo.jpg";
import { CardWithButton } from "UIPalette/CardWithButton/CardWithButton";
import { externalLink } from "core/links";
import { localiseUrl } from "shared/core/locale";
import { t } from "i18next";
import useLocale from "core/hooks/useLocale/useLocale";
import { trackEvents } from "core/monitoring/Events";
import { EventNames } from "core/monitoring/types/enums";

export const parseExLink = (localeId: string): string => {
  const exchangeUri = process.env.REACT_APP_PERKS_AND_DISCOUNTS_URI!;
  return localiseUrl(localeId, exchangeUri);
};

export const PerksAndDiscountsCard = () => {
  const { localeId } = useLocale();
  const externalUri = parseExLink(localeId);

  const handleClickBrightEx = () => {
    trackEvents(EventNames.BRIGHT_EX_CLICK);
    externalLink(externalUri);
  };
  return (
    <CardWithButton buttonClassName="orange-button" buttonText={t("home.perksAndDiscounts.button")} handleClick={handleClickBrightEx}>
      <h2 className="pb-3 text-center">{t("home.perksAndDiscounts.title")}</h2>
      <img className="mx-auto max-w-[240px]" src={BrightExchangeLogo} alt="Bright Exchange Logo" />
      <p className="pt-3 text-center" role="paragraph">
        {t("home.perksAndDiscounts.description")}
      </p>
    </CardWithButton>
  );
};
