import { useTranslation } from "react-i18next";
import OTPInput from "UIPalette/OTPInput/OTPInput";

export const Verify = ({ handleChangeOtp }: { otp: string; handleChangeOtp: (_otp: string) => void }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "accountSecurity.twoFactorAuth.verify" });

  return (
    <div className="space-y-6 lg:space-y-8">
      <div className="">
        <h6 className="text-xl lg:text-3xl font-bold">{t("title")}</h6>
        <p>{t("description")}</p>
      </div>
      <OTPInput handleChange={handleChangeOtp} />
    </div>
  );
};
